import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>Upptäck hur våra produkter jämförs</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produkter/',
    label: 'SE ALLA PRODUKTER',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul>\n<li>*Med hårt smutsad disk</li>\n<br/><li>**Rengöringsingredienser vs. Finish Power</li>\n</ul>',
      rows: [
        {
          text: 'Eko-certifierad',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt00e5faf1bda40d49/676a9da8897425e1f68f191d/svgviewer-png-output_(1).png',
        },
        {
          text: 'Rengör',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt69c6e531f78cfc49/67605f9cde48eedf40718bde/Rengor.svg',
        },
        {
          text: 'Glans',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltde6ea1585c31c0a0/67605f9d25d6044e102c008d/Glans.svg',
        },
        {
          text: '<strong>48h</strong> intorkade matrester*',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7dd7124ea00d9d8b/67605f9c83c5183ef7d78142/48h_intorkade_matrester.svg',
        },
        {
          text: 'Tuff mot fett',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt60f1d5ebd40a9867/67605f9c6b6ba34f0b55b2c6/Tuff_mot_fett.svg',
        },
        {
          text: 'Sluta Förskölj',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8427db5df7e7ef14/67605f9c63b9aa6805292c02/Sluta_Forskolj.svg',
        },
        {
          text: 'Snabb upplösning',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt47f9ff5bf00483da/67605f9c531c396105068d26/Snabb_upplosning.svg',
        },
        {
          text: 'Mindre kemisk vikt**',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltce176fab395992f4/67605f9cadf9fb3dcb8a4387/Mindre_kemisk_vikt.svg',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt3cee3b4848af57d8/6760541eabb68bdd73c28551/Ultimate.webp',
          productName: 'ULTIMATE',
          productLink: '/produkter/diskmedel/quantum-ultimate-disktabletter/70-regular/',
          color: 'black-gradient',
          values: [
            {
              image:
                'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt77408d25d52c8f92/676a9da847a72e702b852f20/svgviewer-png-output_(2).png',
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd1c693a13a203782/6760541ef3bd9b1e12e03404/Power.webp',
          productName: 'POWER',
          productLink: '/produkter/diskmedel/all-in-1-max-disktabletter/42-regular/',
          color: 'dark-blue-gradient',
          values: [
            {
              image:
                'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt77408d25d52c8f92/676a9da847a72e702b852f20/svgviewer-png-output_(2).png',
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'FINISH POWER',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt4a585b3e017c8e37/6760541ea398c3593d78d2b5/Power_podium.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/produkter/diskmedel/all-in-1-max-disktabletter/42-regular/',
        text: 'Upptäck Power',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd0c4a69ff3074bb6/6760541e6dece3c72885eb86/Tuff_Mot_Fett_1.webp',
          name: 'TUFF MOT FETT',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH ULTIMATE',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt208508f4119c88ff/677ff82a44ed80414f38370a/Finish_Ultimate-30.webp',
      cardStyle: 'black',
      button: {
        link: '/produkter/diskmedel/quantum-ultimate-disktabletter/70-regular/',
        text: 'Upptäck vår bästa, Ultimate',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt93abb389169328ec/6760541e58f539625e6b989d/48h_Intorkade_Matrester.webp',
          name: '<strong>48H</strong><br/>INTORKADE MATRESTER*',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd0c4a69ff3074bb6/6760541e6dece3c72885eb86/Tuff_Mot_Fett_1.webp',
          name: 'TUFF MOT FETT',
        },
      ],
      mobileOrder: 1,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: 'NICHTS MEHR VERPASSEN',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7512496341f5a468/66f53c689441d6ee01fe1a7d/newsletter_popup_image.png',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c4941b86e10813/66f53c833097ebb254ddd0d8/newsletter_popup_image_mobile.png',
    alt: 'finish',
  },
  thankYou: {
    headline: 'VIELEN DANK FÜR IHRE ANMELDUNG FÜR DEN FINISH NEWSLETTER!',
    description: '',
    closeButton: {
      label: 'schließen',
      redirectUrl: '/',
    },
  },
  form: {
    id: '7f4311c4-00e3-48f9-a9cb-13b20ef2b28f',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'Bitte füllen Sie die erforderlichen Felder aus.',
  },
};
